import "bootstrap";
import "cocoon-js";
import "tablednd";

import "selectize/dist/js/standalone/selectize.min";
import "selectize/dist/css/selectize.css";
import "pikaday/css/pikaday.css";

import "styles/theme/theme.min.css";
import "styles/dashboard.scss";
import "styles/estimation.scss";

import "jquery/_datepicker";
import "jquery/_previewFile";
import "jquery/_calcPrice";
import "jquery/_constructionItems";

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require.context("../images/", true);
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
