import {
  addListenerForEstimateItem,
  addListenerForActualCostItem,
} from "jquery/_calcPrice";

const DISMANTLING_TYPE = "解体";
const TEMPORARY_TYPE = "仮設";
const EXPENSES_TYPE = "諸経費";
const NEW_CONSTRUCTION_TYPE = "新設";
const SITE_MANAGEMENT_FEE = "現場管理費";
const GENERAL_FEE = "一般管理費";
const EXPENSES = "諸経費";
const OTHER_PROFIT = "その他（弊社利益）";
const FREE_FORM = "free_form";

const FREE_FORM_COMMON = "自由記述（共通）";
const FREE_FORM_TEMPORARY = "自由記述（仮設）";
const FREE_FORM_DISMANTLING = "自由記述（解体）";
const FREE_FORM_NEW_CONSTRUCTION = "自由記述（新設）";

const CATEGORY = {
  common: "共通",
  temporary: "仮設",
  dismantling: "解体",
  new_construction: "新設",
  expenses: "諸経費",
  free_form: "自由記述",
};

const buildConstructionItems = (ci) => {
  console.log("buildConstructionItems");
  const constructionItems = ci;
  const newConstructionItems = sortConstructionItems(constructionItems);

  populateConstructionContent(newConstructionItems);
};

let freeFormItems = [];

const calculateAllPriceUnderCategory = (categoryType) => {
  console.log("calculateAllPriceUnderCategory");
  let total = 0;

  $(".js-classification").each(function (index, item) {
    if (item.textContent === categoryType) {
      const $selectedCategory = $(item)
        .closest(".js-estimateItem")
        .find(".js-construction-item-id")
        .find("option:selected");
      if (
        $selectedCategory.text() !== SITE_MANAGEMENT_FEE &&
        $selectedCategory.text() !== GENERAL_FEE &&
        $selectedCategory.text() !== OTHER_PROFIT
      ) {
        const $price = $(item)
          .closest(".js-estimateItem")
          .find(".js-estimatePrice");
        const $count = $(item)
          .closest(".js-estimateItem")
          .find(".js-estimateCount");

        if (!isNaN($price.val())) {
          total += Number($price.val()) * Number($count.val() || 1);
        }
      }
    }
  });
  return total;
};

const calculateSiteManagementFee = (type) => {
  console.log("calculateSiteManagementFee");
  let total = 0;

  $(".js-classification").each(function (index, item) {
    if (item.textContent === type) {
      const $selectedCategory = $(item)
        .closest(".js-estimateItem")
        .find(".js-construction-item-id")
        .find("option:selected");
      if ($selectedCategory.text() === SITE_MANAGEMENT_FEE) {
        const $price = $(item)
          .closest(".js-estimateItem")
          .find(".js-estimatePrice");
        const $count = $(item)
          .closest(".js-estimateItem")
          .find(".js-estimateCount");

        if (!isNaN($price.val())) {
          total += Number($price.val()) * Number($count.val() || 1);
        }
      }
    }
  });
  return total;
};

const calculateGeneralFee = (type) => {
  console.log("calculateGeneralFee");
  let total = 0;

  $(".js-classification").each(function (index, item) {
    if (item.textContent === type) {
      const $selectedCategory = $(item)
        .closest(".js-estimateItem")
        .find(".js-construction-item-id")
        .find("option:selected");
      if ($selectedCategory.text() === GENERAL_FEE) {
        const $price = $(item)
          .closest(".js-estimateItem")
          .find(".js-estimatePrice");
        const $count = $(item)
          .closest(".js-estimateItem")
          .find(".js-estimateCount");

        if (!isNaN($price.val())) {
          total += Number($price.val()) * Number($count.val() || 1);
        }
      }
    }
  });
  return total;
};

const calculateAllPrice = () => {
  console.log("calculateAllPrice");
  let total = 0;

  $(".js-classification").each(function (index, item) {
    const $price = $(item)
      .closest(".js-estimateItem")
      .find(".js-estimatePrice");
    if (!isNaN($price.val())) {
      const $count = $(item)
        .closest(".js-estimateItem")
        .find(".js-estimateCount");

      total += Number($price.val()) * Number($count.val() || 1);
    }
  });
  return total;
};

const isExpensesExisted = () => {
  console.log("isExpensesExisted");
  let isExisted = false;
  $(".js-classification").each(function (index, item) {
    if (item.textContent === EXPENSES_TYPE) {
      isExisted = true;
      return false;
    }
  });
  return isExisted;
};

const clearAllConstructionItemTitle = () => {
  console.log("clearAllConstructionItemTitle");
  $(".js-classification").each(function (index, item) {
    const $title = $(item)
      .closest(".js-estimateItem")
      .find(".js-construction-item-title");
    $title.val("");
  });
};

const isSiteManagementFeeExisted = (type, itemCategory) => {
  console.log("isSiteManagementFeeExisted");
  let numberOfRecord = 0;
  $(".js-classification").each(function (index, item) {
    if (
      item.textContent === type ||
      (item.textContent === "" && itemCategory === type)
    ) {
      const $selectedCategory = $(item)
        .closest(".js-estimateItem")
        .find(".js-construction-item-id")
        .find("option:selected");
      if ($selectedCategory.text() === SITE_MANAGEMENT_FEE) {
        numberOfRecord++;
        if (numberOfRecord > 1) return false;
      }
    }
  });
  return numberOfRecord <= 1 ? false : true;
};

const isGeneralFeeExisted = (type, itemCategory) => {
  console.log("isGeneralFeeExisted");
  let numberOfRecord = 0;
  $(".js-classification").each(function (index, item) {
    if (
      item.textContent === type ||
      (item.textContent === "" && itemCategory === type)
    ) {
      const $selectedCategory = $(item)
        .closest(".js-estimateItem")
        .find(".js-construction-item-id")
        .find("option:selected");
      if ($selectedCategory.text() === GENERAL_FEE) {
        numberOfRecord++;
        if (numberOfRecord > 1) return false;
      }
    }
  });
  return numberOfRecord <= 1 ? false : true;
};

const handleFreeFormConstructionItem = (
  text,
  $target,
  $unitfield,
  $defaultPrice,
  $classificationfield
) => {
  const $title = $target
    .closest(".js-estimateItem")
    .find(".js-construction-item-title");
  $target.hide();
  $title.show();
  // 自由記述欄の追加・更新時に、単位を-、単価を0円に初期値にリセットする箇所を削除
  //$unitfield.val("-");
  //$defaultPrice.val(0);
  // set classification manually due to they are not from master data
  switch (text) {
    case FREE_FORM_COMMON:
      $classificationfield.html(CATEGORY["common"]);
      break;
    case FREE_FORM_TEMPORARY:
      $classificationfield.html(CATEGORY["temporary"]);
      break;
    case FREE_FORM_DISMANTLING:
      $classificationfield.html(CATEGORY["dismantling"]);
      break;
    case FREE_FORM_NEW_CONSTRUCTION:
      $classificationfield.html(CATEGORY["new_construction"]);
      break;
    default:
      break;
  }
};

const populateDefaultPriceAndActualPrice = (
  textSelected,
  $defaultPrice,
  $actualPriceField,
  categoryType
) => {
  if (textSelected === SITE_MANAGEMENT_FEE) {
    const value = 0.2013 * calculateAllPriceUnderCategory(categoryType);
    $defaultPrice.val(value);
    $actualPriceField.val(value);
    return;
  } else if (textSelected === GENERAL_FEE) {
    const value =
      0.1724 *
      (calculateAllPriceUnderCategory(categoryType) +
        calculateSiteManagementFee(categoryType));
    $defaultPrice.val(value);
    $actualPriceField.val(value);
    return;
  } else if (textSelected === OTHER_PROFIT) {
    const value =
      0.1 *
      (calculateAllPriceUnderCategory(categoryType) +
        calculateSiteManagementFee(categoryType) +
        calculateGeneralFee(categoryType));
    $defaultPrice.val(value);
    $actualPriceField.val(value);
    return;
  }
};

const changeClassification = (e) => {
  console.log("changeClassification");
  const $target = $(e.target);
  const optionSelected = $target.find("option:selected");
  const textSelected = optionSelected.text();
  const $classificationfield = $target
    .closest(".js-estimateItem")
    .find(".js-classification");

  //見積もり編集時に自由記述内容が隠れる問題に対処するため、changeClassificationの呼び出しイベントを追加。項目未選択にも本箇所が動作することになり、以下処理を省略するために条件追加。
  if(optionSelected[0] !== undefined){
    const itemCategory = optionSelected[0].getAttribute("category");

    const $unitfield = $target.closest(".js-estimateItem").find(".js-unit");
    const $countfield = $target
      .closest(".js-estimateItem")
      .find(".js-estimateCount");
    const $defaultPrice = $target
      .closest(".js-estimateItem")
      .find(".js-estimatePrice");

    const $actualCountField = $target
      .closest(".js-estimateItem")
      .find(".js-actualCostCount");
    const $actualPriceField = $target
      .closest(".js-estimateItem")
      .find(".js-actualCostPrice");

    if (
      [
        FREE_FORM_COMMON,
        FREE_FORM_TEMPORARY,
        FREE_FORM_DISMANTLING,
        FREE_FORM_NEW_CONSTRUCTION,
      ].includes(textSelected)
    ) {
      handleFreeFormConstructionItem(
        textSelected,
        $target,
        $unitfield,
        $defaultPrice,
        $classificationfield
      );
      return;
    }

    if (textSelected === EXPENSES && isExpensesExisted(textSelected)) {
      $target.val($target.data("preValue"));
      $("#duplicatedItemModal").modal();
      return;
    }

    if (
      textSelected === SITE_MANAGEMENT_FEE &&
      itemCategory === DISMANTLING_TYPE &&
      isSiteManagementFeeExisted(DISMANTLING_TYPE, itemCategory)
    ) {
      $target.val($target.data("preValue"));
      $("#duplicatedItemModal").modal();
      return;
    }

    if (
      textSelected === SITE_MANAGEMENT_FEE &&
      itemCategory === TEMPORARY_TYPE &&
      isSiteManagementFeeExisted(TEMPORARY_TYPE, itemCategory)
    ) {
      $target.val($target.data("preValue"));
      $("#duplicatedItemModal").modal();
      return;
    }

    if (
      textSelected === GENERAL_FEE &&
      itemCategory === DISMANTLING_TYPE &&
      isGeneralFeeExisted(DISMANTLING_TYPE, itemCategory)
    ) {
      $target.val($target.data("preValue"));
      $("#duplicatedItemModal").modal();
      return;
    }

    if (
      textSelected === GENERAL_FEE &&
      itemCategory === TEMPORARY_TYPE &&
      isGeneralFeeExisted(TEMPORARY_TYPE, itemCategory)
    ) {
      $target.val($target.data("preValue"));
      $("#duplicatedItemModal").modal();
      return;
    }

    const value = $target.val();

    if (value.length > 0) {
      $.ajax({
        type: "GET",
        url: `/dashboard/construction_items/${value}`,
        dataType: "json",
        success: function (response) {
          const categoryi18n = response["category_i18n"];
          const unit = response["unit"];
          const price = response["default_price"];
          $classificationfield.html(categoryi18n);
          $unitfield.val(unit);
          $target.data("preValue", value);
          $defaultPrice.val(0);
          if (categoryi18n === DISMANTLING_TYPE) {
            populateDefaultPriceAndActualPrice(
              textSelected,
              $defaultPrice,
              $actualPriceField,
              DISMANTLING_TYPE
            );
            return;
          } else if (categoryi18n === TEMPORARY_TYPE) {
            populateDefaultPriceAndActualPrice(
              textSelected,
              $defaultPrice,
              $actualPriceField,
              TEMPORARY_TYPE
            );
            return;
          } else if (categoryi18n === NEW_CONSTRUCTION_TYPE) {
            populateDefaultPriceAndActualPrice(
              textSelected,
              $defaultPrice,
              $actualPriceField,
              NEW_CONSTRUCTION_TYPE
            );
            return;
          } else if (categoryi18n === EXPENSES_TYPE) {
            $countfield.val(1);
            $actualCountField.val(1);
            const price = calculateAllPrice();
            $actualPriceField.val(0.15 * price);
            $defaultPrice.val(0.15 * price);

            return;
          }
          if (price.length > 0) {
            $defaultPrice.val(price);
          }
        },
      });
    }
  }
};

const populateConstructionContent = (constructionItems) => {
  console.log("populateConstructionContent");

  replaceContentOptions(constructionItems);
  const value = $(".js-construction-type").val();
  if (value !== FREE_FORM) {
    $(".js-construction-item-title").hide();
    $(".js-construction-item-id").show();
    clearAllConstructionItemTitle();
  }
  $(".js-construction-item-id").each(function (index, item) {
    if (index < constructionItems.length) {
      item.value = constructionItems[index]["id"];
      $(item).data("preValue", constructionItems[index]["id"]);
    } else {
      $(item).closest("tr").remove();
    }
  });

  $(".js-unit").each(function (index, item) {
    if (index < constructionItems.length) {
      $(item).val(constructionItems[index]["unit"]);
    }
  });

  $(".js-classification").each(function (index, item) {
    if (index < constructionItems.length) {
      item.innerText = constructionItems[index]["category_i18n"];
    }
  });

  $(".js-estimatePrice").each(function (index, item) {
    if (index < constructionItems.length) {
      $(item).val(constructionItems[index]["default_price"]);
    }
  });

  $(".js-actualCostPrice").each(function (index, item) {
    if (index < constructionItems.length) {
      $(item).val(constructionItems[index]["default_price"]);
    }
  });
  $("#estimate-construction-table").tableDnD({
    onDragClass: "myDragClass",
  });
};

const getValue = (data, key) => {
  console.log("getValue");
  return data[key] && data[key].length > 0 ? data[key] : [];
};

const buildSelectedOptions = () => {
  console.log("buildSelectedOptions");
  let result = {};
  const selectedOptions = $(".js-construction-item-id").find("option:selected");
  selectedOptions.each((index, item) => {
    const category = item.getAttribute("category");
    if (category) {
      const value = getValue(result, category);
      result = { ...result, [category]: [...value, item] };
    }
  });
  return result;
};

const getSelectedValues = () => {
  console.log("getSelectedValues"); 
  let result = [];
  const selectedOptions = $(".js-construction-item-id").find("option:selected");  
  selectedOptions.each((index, item) => {
    result.push($(item).val());
  });
  console.log( $(".js-construction-item-id").find("option:selected") );

  return result;
};

const replaceDOM = (options) => {
  console.log("replaceDOM");
  $(".js-construction-item-id").find("option").remove();
  $(Object.keys(options)).each((index, category) => {
    $(".js-construction-item-id").append(
      $("<option disabled></option>").text(
        "---------- " + category + " ----------"
      )
    );
    $(options[category]).each((index, option) => {
      $(".js-construction-item-id").append(option);
    });
  });
};

const replaceContentOptions = (constructionItems) => {
  console.log("replaceContentOptions");
  const freeFormCategory = freeFormItems.map((item) => {
    return {
      ...item,
      category: FREE_FORM,
      category_i18n: "自由記述",
    };
  });
  const newConstructionItems = [
    ...constructionItems.slice(0, constructionItems.length - 1),
    ...freeFormCategory,
    constructionItems[constructionItems.length - 1],
  ].filter((item) => item);
  let options = buildSelectedOptions();
  $(newConstructionItems).each((index, item) => {
    const value = getValue(options, item.category_i18n);
    const newOption = $("<option></option>")
      .attr("value", item.id)
      .attr("category", item.category_i18n)
      .text(item.title);

    const newOptions = [...value];

    if (!value.some((option) => option.text === item.title)) {
      newOptions.push(newOption);
    }

    options = { ...options, [item.category_i18n]: newOptions };
  });

  replaceDOM(options);
};

const sortConstructionItems = (constructionItems) => {
  console.log("sortConstructionItems");
  // get list of free form first
  freeFormItems = [];
  return (
    constructionItems
      .map((item) => {
        if (
          [
            FREE_FORM_COMMON,
            FREE_FORM_TEMPORARY,
            FREE_FORM_DISMANTLING,
            FREE_FORM_NEW_CONSTRUCTION,
          ].includes(item.title)
        ) {
          freeFormItems.push(item);
          return undefined;
        }
        if (item.category_i18n === CATEGORY["common"]) {
          return {
            ...item,
            priority: 1,
          };
        }
        if (item.category_i18n === CATEGORY["temporary"]) {
          return {
            ...item,
            priority: 2,
          };
        }
        if (item.category_i18n === CATEGORY["dismantling"]) {
          return {
            ...item,
            priority: 3,
          };
        }
        if (item.category_i18n === CATEGORY["new_construction"]) {
          return {
            ...item,
            priority: 4,
          };
        }
        if (item.category_i18n === CATEGORY["expenses"]) {
          return {
            ...item,
            priority: 5,
          };
        }
      })
      .filter((item) => item)
      // sort list by common/temporary/dismantling/new establish
      .sort((a, b) => a.priority - b.priority)
  );
};

const changeConstructionItems = (e) => {
  console.log("changeConstructionItems");
  const $target = $(e.target);
  const value = $target.val();

  clearAllConstructionItemTitle();

  if (value === FREE_FORM) {
    const estimateId = $("#estimate_id").val();
    $.ajax({
      type: "GET",
      url: `/dashboard/free_form_construction_items`,
      data: { construction_type: value, estimate_id: estimateId },
      dataType: "script",
    });
    $("#estimate-construction-table").tableDnD({
      onDragClass: "myDragClass",
    });
  } else {
    $(".js-construction-item-id").show();
    $(".js-construction-item-title").hide();
    if (value.length > 1) {
      $.ajax({
        type: "GET",
        url: `/dashboard/build_construction_items`,
        data: { construction_type: value },
        dataType: "script",
      });
    }
  }
};

const initConstructionItem = () => {
  console.log("initConstructionItem");
  const value = $(".js-construction-type").val();
  const estimateId = $("#estimate_id").val();
  const index = $("#estimate_construction_items tr").length;
  if (value === FREE_FORM) {
    $.ajax({
      type: "GET",
      url: `/dashboard/free_form_construction_items`,
      data: {
        construction_type: value,
        estimate_id: estimateId,
        single: true,
        index: index,
      },
      dataType: "script",
    });
  } else if (value.length > 1) {
    // ajax because if we delete all and then try to add new construction item
    $(".js-construction-item-id").show();
    $(".js-construction-item-title").hide();
    if (value.length > 1) {
      $.ajax({
        type: "GET",
        url: `/dashboard/build_construction_items`,
        data: { construction_type: value, single: true },
        dataType: "script",
      });
    }
  }
};


const addListenerForConstructionItem = () => {
  console.log("addListenerForConstructionItem");
  $(".js-construction-item-id").on("change", changeClassification);
  //編集時、自由記述欄が項目名に隠れてしまう問題を解決するために追加
  $(".js-construction-item-id").on("focus", changeClassification);
  $(".js-construction-type").on("change", changeConstructionItems);
  $(".js-auto-calculate-btn").on("click", recalculateAutoCalcCosts);
};


const populateConstructionItemOptions = (ci) => {
  console.log("populateConstructionItemOptions");
  

  //見積区分
  const constructionType = $(".js-construction-type").val();
  const constructionItems = ci;

  //
  const sortedConstructionItems = sortConstructionItems(constructionItems);
  if (constructionType !== FREE_FORM) {

    //自由記述項目
    const freeFormCategory = freeFormItems.map((item) => {
      return {
        ...item,
        category: FREE_FORM,
        category_i18n: "自由記述",
      };
    });

    //自由記述項目を加えた見積項目
    const newConstructionItems = [
      ...sortedConstructionItems.slice(0, sortedConstructionItems.length - 1),
      ...freeFormCategory,
      sortedConstructionItems[sortedConstructionItems.length - 1],
    ].filter((item) => item);

    //選択済の見積項目の番号
    const selectedValue = getSelectedValues();

    //option?? 入力項目を作っている？
    let options = {};
    $(newConstructionItems).each((index, item) => {
      const value = getValue(options, item.category_i18n);
      const newOption = $("<option></option>")
        .attr("value", item.id)
        .attr("category", item.category_i18n)
        .text(item.title);

      const newOptions = [...value];

      if (!value.some((option) => option.text === item.title)) {
        newOptions.push(newOption);
      }

      options = { ...options, [item.category_i18n]: newOptions };
    });
    replaceDOM(options);

    $(".js-construction-item-id").each(function (index, item) {
      if (index < constructionItems.length) {
        item.value = selectedValue[index];
        $(item).data("preValue", selectedValue[index]);
      }
    });
  }
};

const recalculateAutoCalcCosts = (e) => {
  console.log("recalculateAutoCalcCosts");

  const AUTO_CALC_COSTS = [SITE_MANAGEMENT_FEE, GENERAL_FEE, OTHER_PROFIT];

  $(".js-construction-item-id").each(function (index, item) {
    const selectedItem = $(item).find("option:selected").text();
    const $estimateItemRow = $(item).closest(".js-estimateItem");
    const classification = $estimateItemRow.find(".js-classification").text();
    const $defaultPrice = $estimateItemRow.find(".js-estimatePrice");
    const $actualPriceField = $estimateItemRow.find(".js-actualCostPrice");
    if(AUTO_CALC_COSTS.includes(selectedItem)){
      populateDefaultPriceAndActualPrice(
        selectedItem,
        $defaultPrice,
        $actualPriceField,
        classification
      );
      $defaultPrice.trigger("change");
    }
  });
}

$(document).on("turbolinks:load", () => {
  $("#estimate-construction-table").tableDnD({
    onDragClass: "myDragClass",
    onDragStop: function (table, row) {
      $(table).find(".js-position")
        .each(function(idx){
          $(this).val(idx + 1);
        });
    },
  });

  addListenerForConstructionItem();

  $(".js-addEstimateItem").on("click", () => {
    // cocoon:after-insert looks not work so add event listener to on click button
    setTimeout(() => {
      addListenerForConstructionItem();
      initConstructionItem();
      $("#estimate-construction-table").tableDnD({
        onDragClass: "myDragClass",
      });
    }, 300);
  });

  // in edit mode
});
window.buildConstructionItems = buildConstructionItems;
window.populateConstructionItemOptions = populateConstructionItemOptions;
window.changeClassification = changeClassification;
