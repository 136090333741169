import $ from "jquery"
import Pikaday from "pikaday"

$(document).on("turbolinks:load", () => {
  const i18n = {
    previousMonth: "前の月",
    nextMonth: "次の月",
    months: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
    weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
  }
  $(".js-datepicker").each((i, el) => {
    return new Pikaday({ field: $(el)[0], format: "Y/M/D", i18n })
  })
})
