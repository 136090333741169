import $ from "jquery";

$(document).on("turbolinks:load", () => {
  const previewFiles = (e) => {
    const $target = $(e.target),
      $fileUploader = $target.closest(".js-file-upload"),
      files = $fileUploader.prop("files"),
      maxfiles = parseInt($fileUploader.attr("data-max-file")),
      previewClass = `.${$fileUploader.attr("data-preview-class")}`,
      $preview = $(previewClass);
    // reset preview
    $preview.html("");
    if (maxfiles && files.length > maxfiles) {
      alert(`${maxfiles}つ以上のファイルをアップロードしないでください`);
      $fileUploader.val("");
      return;
    }
    if (files) {
      $.each(files, function (index, file) {
        if (file.size > 10485760) {
          $fileUploader.val("");
          alert("アップロードできる画像のサイズは最大10MBです。");
          return;
        }
        previewFile(file, $preview, $fileUploader);
      });
    }
  };

  const previewFile = (file, $preview, $fileUploader) => {
    const textName = $fileUploader.attr("data-text-name");
    const reader = new FileReader();
    reader.onload = function (event) {
      let textarea = "";
      if (textName) {
        textarea = `<textarea name='${textName}_new[][comment]' class='col-md-6 form-control mt-3' cols='6' rows='2'></textarea>`;
      }
      if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
        $preview.append(`<div class='col-md-6 p-3'>
          <img height='100' width='100' src=${event.target.result}>${textarea}
          </div>`);
      } else {
        $preview.append(`<div class='col-md-6 p-3'>
          <span>${file.name}</span>${textarea}</div>`);
      }
    };
    reader.readAsDataURL(file);
  };

  const addPreviewFileListener = () => {
    $(".js-file-upload").on("change", previewFiles);
  };

  addPreviewFileListener();
});
