import $ from "jquery";

const PRICE_TYPE = "price";
const COUNT_TYPE = "count";

export const addListenerForEstimateItem = () => {
  $(".js-estimatePrice").on("change", (e) =>
    calcEstimateItemPrice(e, PRICE_TYPE)
  );
  $(".js-estimateCount").on("change", (e) =>
    calcEstimateItemPrice(e, COUNT_TYPE)
  );
};

export const calcActualCostItemTotalPrice = (e) => {
  const $target = $(e.target);
  const $price = $target
    .closest(".js-estimateItem")
    .find(".js-actualCostPrice");
  const $count = $target
    .closest(".js-estimateItem")
    .find(".js-actualCostCount");
  const $totalPrice = $target
    .closest(".js-estimateItem")
    .find(".js-actualCostItemTotalPrice");
  $totalPrice.text(Number($price.val()) * Number($count.val()));
};
export const calcEstimateItemPrice = (e, type) => {
  const $target = $(e.target);
  const $price = $target.closest(".js-estimateItem").find(".js-estimatePrice");
  const $count = $target.closest(".js-estimateItem").find(".js-estimateCount");
  const $totalPrice = $target
    .closest(".js-estimateItem")
    .find(".js-estimateItemTotalPrice");
  $totalPrice.text(Number($price.val()) * Number($count.val()));

  populateActualCostValue(e, type, Number($price.val()), Number($count.val()));
};

export const populateActualCostValue = (event, type, estPrice, estCount) => {
  const $target = $(event.target);

  if (type === PRICE_TYPE) {
    $target
      .closest(".js-estimateItem")
      .find(".js-actualCostPrice")
      .val(estPrice);
  }
  if (type === COUNT_TYPE) {
    $target
      .closest(".js-estimateItem")
      .find(".js-actualCostCount")
      .val(estCount);
  }

  calcActualCostItemTotalPrice(event);
};

export const addListenerForActualCostItem = () => {
  $(".js-actualCostPrice").on("change", calcActualCostItemTotalPrice);
  $(".js-actualCostCount").on("change", calcActualCostItemTotalPrice);
};

$(document).on("turbolinks:load", () => {
  addListenerForEstimateItem();

  $(".js-addEstimateItem").on("click", () => {
    // cocoon:after-insert looks not work so add event listener to on click button
    setTimeout(() => {
      addListenerForEstimateItem();
    }, 300);
  });

  addListenerForActualCostItem();

  $(".js-addEstimateItem").on("click", () => {
    // cocoon:after-insert looks not work so add event listener to on click button
    setTimeout(() => {
      addListenerForActualCostItem();
    }, 300);
  });
});
window.addListenerForEstimateItem = addListenerForEstimateItem;
window.addListenerForActualCostItem = addListenerForActualCostItem;
